import React, {useState} from "react";
import "./index.css"
import $ from 'jquery'
import AuthService from "../../services/auth.service";
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const [loginError, setLoginError] = useState("")
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await AuthService.login(username, password)
                .then(
                    async () => {
                        navigate('/')
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        window.location.reload()
                    },
                    (error) => {
                        setLoginError(error.response.data.message)
                    })

        } catch (err) {
            console.log(err)
        }
    };

    $(document).ready(function(event) {
        $('form').submit(function(event){
            event.preventDefault();
        });
    });

    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-lg-5">
                        <div className="login-wrap p-4 p-md-5">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-user-o"></span>
                            </div>
                            <h3 className="text-center mb-4">Войти</h3>
                            <form className="login-form" onSubmit={handleLogin}>
                                <div className="form-group">
                                    <input type="text" className="form-control rounded-left"
                                           placeholder="Логин или Почта"
                                           onChange={e => setUsername(e.target.value)} required name="username"/>
                                </div>
                                <div className="form-group d-flex">
                                    <input type="password" className="form-control rounded-left" placeholder="Пароль"
                                           onChange={e => setPassword(e.target.value)} required name="password"/>
                                </div>
                                {loginError !== "" ?
                                    <div className="error-box w-100 text-center">
                                        <span className="error-text">{loginError}</span>
                                    </div>
                                    : null
                                }

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="form-control btn btn-primary rounded submit px-3">
                                        Войти
                                    </button>
                                </div>
                                <div className="form-group d-md-flex">
                                    <div className="form-check w-50">
                                        <label className="checkbox-wrap checkbox-primary">
                                            <input onChange={e => setRememberMe(e.target.checked)}
                                                   className="form-check-input" type="checkbox" defaultChecked
                                                   id="rememberMe"/>
                                            <label className="form-check-label" htmlFor="rememberMe">
                                                Запомнить меня
                                            </label>
                                        </label>
                                    </div>
                                    <div className="text-md-end w-50">
                                        <a href="#">Забыли пароль?</a>
                                    </div>
                                </div>
                            </form>
                            <a href="/signup">Или зарегистрируйтесь сейчас</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
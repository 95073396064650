import axios from "axios";
import api from "../http";

const signup = (username, email, password) => {
    return axios
        .post("/api/auth/signup", {
            username,
            email,
            password,
        })
        .catch((error) => {
            console.log(error)
            throw error
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem("user", JSON.stringify(response.data.user));
            }

            return response.data;
        });
};

const login = (email, password) => {
    return axios
        .post('/api/auth/signin', {
            "login": email,
            "password": password,
        })
        .catch((error) => {
            console.log(error)
            throw error
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken);
            }
            return response.data;
        })
};

const deleteAccount = (user_id) => {
    return api
        .delete('/auth/delete')
        .catch((error) => {
            console.log(error)
            throw error
        })
        .then((response) => {
            localStorage.removeItem("token");
            return response.data;
        })
};

const logout = () => {
    return api.post('/auth/logout')
        .then((res) => {
            localStorage.removeItem("token")
            return res.data
        })
        .catch((e) => console.log(e))
};

const checkAuth = () => {
    return api.get(`/refresh`, {withCredentials: true})
        .then((res) => {
            localStorage.setItem('token', res.data.accessToken);
            return res.data.user
        })
        .catch((e) => console.log(e))
}

const authService = {
    signup,
    login,
    deleteAccount,
    logout,
    checkAuth
};

export default authService;
import api from "../http";

const getTelegramCode = () => {
    return api.get("/telegram")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error)
            throw error
        });

}

const addTelegramCode = (user_id) => {
    return api.post("/telegram")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error)
            throw error
        });

};

let telegramService = {
    getTelegramCode,
    addTelegramCode
}

export default telegramService
import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import authService from "../../services/auth.service";
import "./style.css"
import {useNavigate} from "react-router-dom";
import {alert, confirm} from 'react-bootstrap-confirmation';
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";

export default function Cabinet() {
    const [currentUser, setCurrentUser] = useState(undefined);

    let navigate = useNavigate();
    useEffect(async () => {
        let user
        if (localStorage.getItem('token')) {
            user = await AuthService.checkAuth()
            if (user) {
                setCurrentUser(user);
            }
        }

    }, []);

    const deleteAccount = async () => {
        const displayConfirm = async () => {
            return await confirm('Вы уверены в том что хотите удалить аккаунт?', {
                okText: "Да",
                okButtonStyle: "danger",
                cancelText: "Нет",
                cancelButtonStyle: "primary"
            });
        };
        if (await displayConfirm()) {
            authService.deleteAccount()
                .then(
                    () => {
                        alert('Ваш аккаунт был успешно удалён.', {okText: "Хорошо", okButtonStyle: "primary"});
                        navigate('/')
                        window.location.reload()
                    },
                    (error) => {
                        alert("Произошла неизвестная ошибка, попробуйте перезагрузить страницу.")
                        console.log(error.response.data.message)

                    })
        }
    }

    return (
        <div className="bg-white h-100">
            <Navbar currentUser={currentUser} setCurrentUser={setCurrentUser}/>
            <div className="container about-container mt-3">
                <div className="text-center">
                    <h6 className="about-text mx-auto mt-2">Личный кабинет находится в разработке. На данный момент
                        регистрация требуется для оповещений по подписке на авторов. При желании вы можете удалить
                        аккаунт и все ваши данные с сайта, воспользовавшись кнопкой "Удалить аккаунт".</h6>
                </div>
            </div>
            {
                currentUser ?
                    <React.Fragment>
                        <div className="container mt-5">
                            <div className="row d-flex justify-content-center delete-account">
                                <button className="w-auto btn btn-danger text-white delete-account-btn"
                                        onClick={deleteAccount}>
                                    Удалить аккаунт
                                </button>
                            </div>
                        </div>
                        <Footer/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="d-flex registration-alert justify-content-center row me-0 text-center"
                             style={{"height": "75%"}}>
                            <h3 className="align-self-center ms-sm-5 col-md-8">Функции личного кабинета доступны только
                                зарегестрированным пользователям. Пожалуйста <a
                                    href="/signup">зарегистрируйтесь</a> или <a
                                    href="/login">войдите в свой аккаунт</a>.</h3>
                        </div>
                        <Footer/>
                    </React.Fragment>
            }
        </div>
    )
}

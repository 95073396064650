import Navbar from "../Navbar";
import React, {useEffect, useState} from "react";
import Footer from "../Footer/Footer";
import AuthService from "../../services/auth.service";
import followService from "../../services/follow.service";
import "./style.css"
import telegramService from "../../services/telegram.service";
import {alert} from "react-bootstrap-confirmation";
import {BouncingBalls} from "react-cssfx-loading";

export default function SubscriptionsPage() {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [userFollowingAuthors, setUserFollowingAuthors] = useState([])
    const [telegramCode, setTelegramCode] = useState(undefined)
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        let user
        if (localStorage.getItem('token')) {
            user = await AuthService.checkAuth()

        }
        if (user) {
            setCurrentUser(user);

            followService.getFollow()
                .then(
                    (result) => {
                        if (result.authors) {
                            setUserFollowingAuthors(result.authors)
                        } else {
                        }
                    },
                    (error) => {
                        console.log(error.response.data)
                    })
                .catch((e) => {
                    console.log(e)
                })
            telegramService.getTelegramCode()
                .then((res) => {
                    setTelegramCode(res.telegram_code)
                })
        }

    }, []);

    const unFollow = async (e) => {
        let author_id = e.currentTarget.parentNode.id
        followService.removeFollow(author_id)
            .then(
                (response) => {
                    let authors = userFollowingAuthors
                    const indexOfObject = authors.findIndex(object => {
                        return object.author_id === author_id;
                    });
                    authors.splice(indexOfObject, 1)
                    setUserFollowingAuthors([...authors])
                },
                (error) => {
                    alert("Произошла ошибка", {okButtonStyle: "danger"})

                    console.log(error.response.data.message)
                })

    }

    const connectTelegram = async () => {
        setLoading(true)
        telegramService.addTelegramCode()
            .then(
                (data) => {
                    let reg_code = data.reg_code
                    setTelegramCode(reg_code)
                    setLoading(false)
                },
                (error) => {
                    setLoading(false)
                    console.log(error.response.data.message)
                    alert("Произошла ошибка", {okButtonStyle: "danger"})
                })
    }


    const getFollowingAuthorsList = (authors) => {
        return authors.map((follow, index) => {
            return (
                <li id={follow.author_id} key={index} className="list-group-item mb-3 d-flex align-items-center">
                    <a href={`/author/${follow.author_id}`}
                       className="ms-2">{`${follow.firstname} ${follow.middlename} ${follow.lastname}`}</a>
                    <button className="btn btn-danger d-flex unfollow-button ms-auto" onClick={unFollow}>Отписаться
                    </button>
                </li>
            )
        })
    }

    const copyTelegramCode = async () => {
        document.getElementById("custom-tooltip").style.display = "inline";
        await navigator.clipboard.writeText(telegramCode)
        setTimeout(function () {
            document.getElementById("custom-tooltip").style.display = "none";
        }, 1000);

    };
    return (
        <div className="bg-white h-100">
            <Navbar currentUser={currentUser} setCurrentUser={setCurrentUser}/>
            <div className="container about-container mt-3 mb-5">
                <div className="text-center">
                    <h6 className="about-text mx-auto mt-2">Здесь вы можете отредактировать список авторов на которых вы
                        подписаны. Если у них появятся новые книги - мы пришлем вам уведомление в нашем телеграм
                        боте. </h6>
                </div>
            </div>
            {
                currentUser ?
                    <React.Fragment>
                        <div className="container mt-5 mb-5">
                            <div className="row">
                                <div className="row">
                                    <ul className="list-group-numbered author-list">
                                        <h2 className={`${userFollowingAuthors.length === 0 ? 'text-center' : 'text-start'} mb-4`}>{userFollowingAuthors.length === 0 ? "Вы пока не подписаны ни на одного автора" : "Вы подписаны на:"}</h2>
                                        {userFollowingAuthors.length !== 0 ? getFollowingAuthorsList(userFollowingAuthors) : null}
                                    </ul>
                                </div>
                            </div>
                            <div className="row justify-content-center connect-telegram-container">
                                {
                                    telegramCode && !loading ?
                                        <>
                                            <h2 className="mb-4 w-100">Подключение телеграм бота</h2>
                                            <div className="telegram-section position-relative">
                                                <p className="form-text ms-5">Вы можете найти нашего телеграмм бота по
                                                    <a target="_blank" href="http://t.me/PodborknigBot"> этой ссылке</a>.
                                                    Для подключения введите этот код <span><span
                                                        className="button-tooltip-container position-relative">
                                                        <strong className="telegram-code rounded"
                                                                style={{cursor: 'pointer'}}
                                                                onClick={copyTelegramCode}>{telegramCode}</strong>
                                                        <span id="custom-tooltip">Скопировано!</span>
                                                    </span></span>
                                                    сразу после команды старт.</p>
                                            </div>
                                        </>
                                        : loading ?
                                            <div className="align-items-center justify-content-center d-flex mt-5">
                                                <BouncingBalls color={'orange'} width={'58px'} height={'20px'}/>
                                            </div>
                                            :
                                            <button className="btn btn-primary w-auto" onClick={connectTelegram}>Подключить
                                                телеграм бота</button>
                                }

                            </div>
                        </div>
                        <Footer/>
                    </React.Fragment>

                    :
                    <React.Fragment>
                        <div className="d-flex registration-alert justify-content-center row me-0 text-center"
                             style={{"height": "75%"}}>
                            <h3 className="align-self-center ms-sm-5 col-md-8">Функции подписок на авторов доступны
                                только
                                зарегестрированным пользователям. Пожалуйста <a
                                    href="/signup">зарегистрируйтесь</a> или <a
                                    href="/login">войдите в свой аккаунт</a>.</h3>
                        </div>
                        <Footer/>
                    </React.Fragment>
            }
        </div>
    )
}
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import $ from "jquery";
import {ReCAPTCHA} from "react-google-recaptcha";
import "./style.css"
const recaptchaRef = React.createRef();
const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

export default function Signup() {

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("")

    let navigate = useNavigate();

    const handleLogin = async (e) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === "") {
            alert("Пожалуйста пройдите капчу")
        } else {
            e.preventDefault();
            try {
                await AuthService.signup(username, email, password)
                    .then(
                        async () => {
                            try {
                                await AuthService.login(username, password)
                                    .then(
                                        () => {
                                            return navigate('/')
                                        },
                                        (error) => {
                                            console.log(error)
                                        })
                            } catch (err) {
                                console.log(err)
                            }
                        },
                        (error) => {
                            setLoginError(error.response.data.message)
                        }
                    );
            } catch (err) {
                console.log(err);
            }
        }
    };

    $(document).ready(function(event) {
        $('form').submit(function(event){
            event.preventDefault();
        });
    });

    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-lg-5">
                        <div className="login-wrap p-4 p-md-5">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-user-o"></span>
                            </div>
                            <h3 className="text-center mb-4">Регистрация</h3>
                            <form className="login-form" onSubmit={handleLogin}>
                                <div className="form-group">
                                    <input type="text" className="form-control rounded-left"
                                           placeholder="Имя пользователя"
                                           onChange={e => setUsername(e.target.value)} required name="login"/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control rounded-left" placeholder="Почта"
                                           onChange={e => setEmail(e.target.value)} required name="email"/>
                                </div>
                                <div className="form-group d-flex">
                                    <input id="passwordInput" type="password" className="form-control rounded-left"
                                           placeholder="Пароль" onChange={e => setPassword(e.target.value)}
                                           name="password" minLength="6"/>
                                </div>
                                {loginError !== "" ?
                                    <div className="error-box w-100 text-center">
                                        <span className="error-text">{loginError}</span>
                                    </div>
                                    : null
                                }
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LcKBb8dAAAAAP9vV_bNGsrwomzbYedlLOCYzuhC"
                                    grecaptcha={grecaptchaObject}
                                />
                                <div className="form-group">
                                    <button
                                        id="submitSignup" type="submit"
                                        className="form-control btn btn-primary rounded submit px-3">
                                        Зарегистрироваться
                                    </button>
                                </div>
                                <p className="registration-text">Нажимая "Зарегистрироваться" в форме регистрации нового
                                    пользователя, вы выражаете согласие с <a href="/terms">Пользовательским
                                        Соглашением</a> и <a href="/policy">Политикой обработки персональных данных</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

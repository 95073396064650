import api from "../http";

const getAuthor = (author_id, user_id) => {
    return api
        .get("/author", {
            params: {
                "author_id": author_id
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error)
            throw error
        });

};


export default getAuthor
import React from "react";
import "./styles.css"

function Footer() {
    return (
        <footer className="footer bg-dark text-white w-100">
            <p className="p1 w-75">Мы благодарим сервис Флибуста который любезно делится своими данными по книгам.</p>
            <p className="p2">Мы стараемся улучшать наш сервис, чтобы подобрать книгу было еще легче. Будем рады
                обратной связи на почту <a
                    href="mailto:podborknig.ru@gmail.com">podborknig.ru@gmail.com</a>
            </p>
            <div className="text-center mt-5">
                <a className="text-white"
                   href="/policy">
                    Политика обработки персональных данных </a>
            </div>
            <div className="text-center mt-1">
                <a className="text-white" href="/terms"> Пользовательское Соглашение</a>
            </div>
        </footer>
    );
}

export default Footer;

import React, {useState} from "react";

import PropTypes from "prop-types";
import "./style.css";
import {alert} from "react-bootstrap-confirmation";
import gradeService from "../../services/grade.service";

export default function Rating({
                                   setUserGrade,
                                   screenWidth,
                                   bookId,
                                   currentUser,
                                   ratingValue = 0
                               }) {
    let rating = Number((ratingValue * 2).toFixed(2));

    const [isZoomActivated, setIsZoomActivated] = useState(false)
    const [delayHandler, setDelayHandler] = useState(null)

    const handlerGradeMouseEnter = () => {
        setIsZoomActivated(true)
        clearTimeout(delayHandler)
    }

    const handleGradeMouseLeave = () => {
        setDelayHandler(setTimeout(() => {
            setIsZoomActivated(false)
        }, 600))
    }

    const handleStarClick = (e) => {
        let grade = e.currentTarget.id
        setUserGrade(grade)
        gradeService.removeGrade(bookId)
            .then(() => {
                gradeService.addGrade(bookId, grade)
                    .then(
                        (res) => {
                            if (res.status !== 200) {
                                alert("Пожалуйста, пройдите регистрацию или войдите в свой аккаунт, чтобы иметь возможность оценивать книги.", {okButtonStyle: "danger"})
                                setUserGrade(null)
                            }
                        })
            })
    }

    return (
        <div className={`d-flex w-auto ${isZoomActivated ? "ms-1" : null}`}
             onMouseEnter={screenWidth > 576 ? handlerGradeMouseEnter : null}
             onMouseLeave={screenWidth > 576 ? handleGradeMouseLeave : null}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
                let CheckStarWidth =
                    rating >= 1
                        ? "100%"
                        : rating > 0
                            ? (rating * 100).toString() + "%"
                            : "0%";
                rating--;

                return (
                    <React.Fragment>
                        {screenWidth > 576 ?
                            <i id={i.toString()} onClick={(e) => handleStarClick(e)}
                               className={`fa fa-star rating-star stars__out grey-star ${i >= 7 ? 'green-star' : i >= 5 ? 'yellow-star-hover' : 'red-star'}`}
                               style={
                                   {
                                       fontWeight: 600,
                                       transform: isZoomActivated ? "scale(1.7,1.7)" : "scale(1, 1)",
                                       "margin-right": isZoomActivated ? "12px" : "5px"
                                   }}>
                                <i
                                    className={`fa fa-star rating-star stars__in ${i >= 7 ? 'green-star' : i >= 5 ? 'yellow-star-hover' : 'red-star'} ${isZoomActivated ? 'grey-star' : 'yellow-star'}`}
                                    style={{width: isZoomActivated ? '100%' : CheckStarWidth, fontWeight: 600}}
                                />
                            </i>
                            :
                            <i id={i.toString()} onClick={(e) => handleStarClick(e)}
                               className={`fa fa-star stars__out grey-star`} style={
                                {fontWeight: 600, "margin-right": "5px"}}>
                                <i
                                    className={`fa fa-star stars__in yellow-star`}
                                    style={{width: CheckStarWidth, fontWeight: 600}}
                                />
                            </i>
                        }
                    </React.Fragment>
                );
            })}
        </div>
    );
}

Rating.propTypes = {
    ratingValue: PropTypes.number,
};

import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import "./style.css"

export default function Navbar({currentUser, setCurrentUser, booksLike, setBooksLike, authorsLike, setAuthorsLike}) {
    const [showDropdown, setShowDropdown] = useState(false)
    const [switchSearch, setSwitchSearch] = useState(false)

    const logOut = async () => {
        try {
            const response = await AuthService.logout();
            setCurrentUser(undefined)

        } catch (e) {
            console.log(e.response?.data?.message);
        }
    };

    return (
        <React.Fragment>
            <header className="navbar p-0 navbar-expand overflow-hidden navbar-dark flex-column flex-sm-row bd-navbar">
                <a href="/" className="navbar-brand nav-header">
                    <img src="/favicon.ico" className="ms-2 me-2 mb-2" width="23"/>
                    Подбор книг
                    <img src="/pepe.gif" alt="" width="50px" className="me-3 ms-2 mb-3 mb-sm-2" height="50px"/>

                </a>
                <div className="navbar-nav-scroll overflow-hidden">
                    <ul className="navbar-nav bd-navbar-nav flex-row nav-ul-scroll">
                        <li className="nav-item d-inline-block"><a className="nav-link" href="/">Подобрать книгу</a>
                        </li>
                        <li className="nav-item d-inline-block"><a className="nav-link" href="/blog">Новости</a></li>
                        <li className="nav-item d-inline-block">
                            <a className="nav-link" href="/subscriptions">
                                Мои подписки
                            </a>
                        </li>
                        <li className="nav-item w-100 d-flex">
                            {
                                window.innerWidth >= 900 && window.location.pathname === '/' ?
                                    <li className="input-group align-items-center mb-1 me-5 justify-content-end">
                                        <input className="form-control nav-books-search"
                                               onChange={e => switchSearch ? setAuthorsLike(e.target.value) : setBooksLike(e.target.value)}
                                               placeholder={switchSearch ? "Поиск авторов по имени" : "Поиск книг по названию"}/>
                                        <div
                                            className="form-check form-switch d-flex justify-content-center search-container align-items-center">
                                            <input className="form-check-input search-switch" onClick={() => {
                                                setAuthorsLike(booksLike)
                                                setBooksLike(authorsLike)
                                                setSwitchSearch(!switchSearch);
                                            }
                                            } type="checkbox" id="searchSwitch"/>
                                        </div>
                                    </li> : null
                            }
                            <button className="btn btn-secondary bg-transparent border-0 ms-sm-auto" type="button"
                                    onClick={() => setShowDropdown(!showDropdown)}>
                                <i className="far fa-user-circle fa-2x "/>
                            </button>
                        </li>
                    </ul>
                </div>
            </header>
            {
                window.innerWidth < 987 && window.location.pathname === '/' ?
                    <div className="input-group w-100 justify-content-center mt-1">
                        <input className="form-control books-search"
                               onChange={e => switchSearch ? setAuthorsLike(e.target.value) : setBooksLike(e.target.value)}
                               placeholder="Искать книги по названию"/>
                        <div
                            className="form-check form-switch d-flex justify-content-center search-container align-items-center">
                            <input className="form-check-input search-switch"
                                   onClick={() => setSwitchSearch(!switchSearch)} type="checkbox" value=""
                                   id="flexCheckDefault"/>
                        </div>
                    </div> : null
            }
            {
                currentUser === undefined ?
                    <div className={`dropdown-menu position-absolute ${showDropdown ? 'show' : ''}`}>
                        <a className="dropdown-item" href="/login">Войти</a>
                        <a className="dropdown-item" href="/signup">Регистрация</a>
                    </div>
                    :
                    <div className={`dropdown-menu position-absolute ${showDropdown ? 'show' : ''}`}
                         aria-labelledby="dropdownMenuButton">
                        <div className="text-center border-bottom">
                            <span>{currentUser?.username}</span>
                        </div>
                        <div className="text-center">
                            <a className="dropdown-item mt-3" href="/cabinet">Личный кабинет</a>
                            <a className="dropdown-item btn" onClick={logOut}>Выйти</a>
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}
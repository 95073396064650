import axios from 'axios';

const api = axios.create({
    withCredentials: true,
    baseURL: '/api',
    proxy: {
        host: 'localhost',
        port: 5000
    }
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})

api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry && error.config.url !== '/refresh') {
        error.config._isRetry = true;
        error.config.headers['is-retry'] = 'true'

        try {
            const response = await axios.get(`/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken);
            return api.request(originalRequest);
        } catch (e) {
            console.log(e)
            if (originalRequest.url === '/books' || originalRequest.url === '/author') {
                return api.request(originalRequest)
            }
        }
    }
    else if (error.response.status === 401 && error.config && error.config.url === '/refresh') {
        localStorage.removeItem("token")
    }
        throw error;
})

export default api;
import api from "../http";

const getGrade = (book_id) => {
    return api
        .get("/grade", {
            params: {
                "book_id": book_id
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response
            } else {
                throw error
            }
        });

};

const addGrade = (book_id, grade) => {
    return api
        .post("/grade", {
            "book_id": book_id,
            "grade": grade
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return error.response
            } else {
                throw error
            }
        });

};

const removeGrade = (book_id) => {
    return api
        .delete("/grade", {
            params: {
                "book_id": book_id
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                return error.response
            } else {
                throw error
            }
        });

};

let gradeService = {
    getGrade,
    addGrade,
    removeGrade
}

export default gradeService
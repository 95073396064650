import React, {useEffect, useState} from "react";
import jQuery from 'jquery'
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import AuthService from "./services/auth.service";
import Books from "./components/Books";
import Navbar from "./components/Navbar";

window.jQuery = jQuery;

function App() {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [booksLike, setBooksLike] = useState('')
    const [authorsLike, setAuthorsLike] = useState('')

    useEffect(async () => {
        let user
        if (localStorage.getItem('token')) {
            user = await AuthService.checkAuth()
        }
        if (user){
            setCurrentUser(user);
        }
    }, []);

    return (
        <div>
            <Navbar setCurrentUser={setCurrentUser} currentUser={currentUser} booksLike={booksLike} authorsLike={authorsLike} setBooksLike={setBooksLike}
                    setAuthorsLike={setAuthorsLike} />
            <AboutUs />
            <Books setCurrentUser={setCurrentUser} currentUser={currentUser} booksLike={booksLike} authorsLike={authorsLike}/>
            <Footer />
        </div>
    );
}

export default App;

import React, {useEffect, useRef, useState} from "react";
import jQuery from 'jquery'
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import BookCard from "../BookCard";
import genres from "../../genres.json"
import "./style.css";
import {BouncingBalls} from "react-cssfx-loading";
import api from "../../http";

window.jQuery = jQuery;

function Books(props) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    const [screenWidth] = useState(window.innerWidth)
    const [defaultAuthorQueryIsSet, setDefaultAuthorQueryIsSet] = useState(true)
    const [loading, setLoading] = useState(false);
    const [areMetaGenresUpdated, setAreMetaGenresUpdated] = useState(false)
    const [currentGenresSearchText, setCurrentGenresSearchText] = useState('')
    const options = genres.genres
    const [selectedOptions, setSelectedOptions] = useState([{label: "Выбрать все", value: "*"}, ...options]);
    let currentVersion = 1678372415;


    let query = {
        queryYearMin: url.searchParams.get("yearMin"),
        queryYearMax: url.searchParams.get("yearMax"),
        queryAvgRateMin: url.searchParams.get("avgRateMin"),
        queryAvgRateMax: url.searchParams.get("avgRateMax"),
        queryTotGradesMin: url.searchParams.get("totalGradesMin"),
        queryTotGradesMax: url.searchParams.get("totalGradesMax"),
        queryLimit: url.searchParams.get("limit"),
        queryOffset: url.searchParams.get("offset"),
        queryOrderBy: url.searchParams.get("orderBy"),
        queryGenreMetaInclude: url.searchParams.get("genresMetaInclude"),
        queryGenresInclude: url.searchParams.get("genresInclude")
    }

    let savedFilters = localStorage.getItem("filters") ? JSON.parse(localStorage.getItem("filters")) : {}
    let defaultGenresMetaInclude = []
    let defaultGenresMetaIncludeList = []
    options.forEach((function(item){
        let i = defaultGenresMetaIncludeList.findIndex(x => x.metaName == item.metaName);
        if(i <= -1){
            defaultGenresMetaIncludeList.push({metaName: item.metaName})
            defaultGenresMetaInclude.push(item.metaName);
        }
        return null;
    }));
    defaultGenresMetaInclude = defaultGenresMetaInclude.join('|')
    const [books, setBooks] = useState([]);
    const [limit, setLimit] = useState(query.queryLimit || 60);
    const [yearMin, setYearMin] = useState(query.queryYearMin || savedFilters.yearMin || 2023);
    const [yearMax, setYearMax] = useState(query.queryYearMax || savedFilters.yearMax || 2024);
    const [avgRateMin, setAvgRateMin] = useState(query.queryAvgRateMin || savedFilters.avgRateMin || 7);
    const [avgRateMax, setAvgRateMax] = useState(query.queryAvgRateMax || savedFilters.avgRateMax || 10);
    const [totalGradesMin, setTotalGradesMin] = useState(query.queryTotGradesMin || savedFilters.totalGradesMin || 5);
    const [totalGradesMax, setTotalGradesMax] = useState(query.queryTotGradesMax || savedFilters.totalGradesMax || 999999);
    const [showOnlySubscribedAuthorsBooks, setShowOnlySubscribedAuthorsBooks] = useState(savedFilters.showOnlySubscribedAuthorsBooks || false);
    const [showOnlyBooksWithoutUserGrades, setShowOnlyBooksWithoutUserGrades] = useState(savedFilters.showOnlyBooksWithoutUserGrades || false)
    const [orderBy, setOrderBy] = useState(query.queryOrderBy || savedFilters.orderBy || "time_desc");
    const [genresMetaInclude, setGenresMetaInclude] = useState(query.queryGenreMetaInclude || Number(localStorage.getItem("version")) === currentVersion ? savedFilters.genresMetaInclude || defaultGenresMetaInclude : defaultGenresMetaInclude);
    const [genresInclude, setGenresInclude] = useState(query.queryGenreMetaInclude || savedFilters.genresMetaInclude || "")

    if (props.author_id && defaultAuthorQueryIsSet) {
        setDefaultAuthorQueryIsSet(false)
        setBooks([]);
        setLimit(query.queryLimit || 60);
        setYearMin(query.queryYearMin || 1);
        setYearMax(query.queryYearMax || 2024);
        setAvgRateMin(query.queryAvgRateMin || 1);
        setAvgRateMax(query.queryAvgRateMax || 10);
        setTotalGradesMin(query.queryTotGradesMin || 1);
        setTotalGradesMax(query.queryTotGradesMax || 999999);
        setGenresMetaInclude(query.queryGenreMetaInclude || defaultGenresMetaInclude);
        setGenresInclude("")
        setOrderBy(query.queryOrderBy || "time_desc");
        setShowOnlySubscribedAuthorsBooks(false)
        setShowOnlyBooksWithoutUserGrades(false)
    }


    const resetFilters = () => {
        localStorage.removeItem("filters")
        setLimit(60);
        setYearMin(2023);
        setYearMax(2024);
        setAvgRateMin(7);
        setAvgRateMax(10);
        setTotalGradesMin(5);
        setTotalGradesMax(999999);
        setShowOnlySubscribedAuthorsBooks(false);
        setShowOnlyBooksWithoutUserGrades(false)
        setOrderBy("time_desc");
        setGenresMetaInclude(defaultGenresMetaInclude);
        setGenresInclude("")
        setSelectedOptions(options)
    }

    // Rerender instantly on change
    const fetchBooks = () => {
        setLoading(true)
        setBooks([])
        if (!props.author_id) {
            localStorage.setItem("filters", JSON.stringify({
                yearMin: yearMin,
                yearMax: yearMax,
                avgRateMin: avgRateMin,
                avgRateMax: avgRateMax,
                totalGradesMin: totalGradesMin,
                totalGradesMax: totalGradesMax,
                user_id: props.currentUser ? (props.currentUser.user_id ? props.currentUser.user_id : 0) : 0,
                showOnlySubscribedAuthorsBooks: showOnlySubscribedAuthorsBooks,
                showOnlyBooksWithoutUserGrades: showOnlyBooksWithoutUserGrades,
                orderBy: orderBy,
                genresMetaInclude: genresMetaInclude,
                genresInclude: genresInclude
            }))
        }
        return api.get('/books', {
            params: {
                limit: limit,
                books_like: props.booksLike,
                authors_like: props.authorsLike,
                author_id: props.author_id ? props.author_id : 0,
                year_min: 1,
                year_max: yearMax,
                avgRateMin: avgRateMin / 2,
                avgRateMax: avgRateMax / 2,
                total_grades_min: totalGradesMin,
                total_grades_max: totalGradesMax,
                showOnlySubscribedAuthorsBooks: showOnlySubscribedAuthorsBooks,
                showOnlyBooksWithoutUserGrades: showOnlyBooksWithoutUserGrades,
                orderBy: orderBy,
                genresMetaInclude: genresMetaInclude === defaultGenresMetaInclude ? "" : genresMetaInclude,
                genresInclude: genresInclude
            }
        })
            .then((res) => {
                setLoading(false)
                setBooks(res?.data?.data)
            })
            .catch((e) => {
                // alert("Произошла ошибка", {okButtonStyle: "danger"})
                console.log(e)
            })
    }

    window.onload = function () {
        fetchBooks()
    }

    const firstLimitUpdate = useRef(true);
    useEffect(() => {
        if (firstLimitUpdate.current) {
            firstLimitUpdate.current = false
        } else {
            const timeOutId = setTimeout(() => fetchBooks(), 0)
            return () => clearTimeout(timeOutId);
        }
    }, [limit])

    const firstSearchUpdate = useRef(true);
    useEffect(() => {
        if (firstSearchUpdate.current) {
            firstSearchUpdate.current = false
        } else {
            const timeOutId = setTimeout(() => fetchBooks(), 1500)
            return () => clearTimeout(timeOutId);
        }
    }, [props.booksLike, props.authorsLike])


    useEffect(() => {
        let version = localStorage.getItem("version");
        if (version) {
            if (version < currentVersion) {
                if (Number(version) >= 1677919340) {
                    let newFilters = JSON.parse(localStorage.getItem("filters"))
                    delete newFilters.genresMetaInclude
                    localStorage.removeItem("filters")
                    localStorage.setItem("filters", JSON.stringify(newFilters));
                    localStorage.setItem("version", currentVersion)
                }
                else if (Number(version) < 1677919340) {
                    localStorage.removeItem("filters")
                    localStorage.setItem("version", currentVersion)
                }
            }
        } else {
            localStorage.removeItem("filters")
            localStorage.removeItem("version")
            localStorage.setItem("version", currentVersion)
        }
    }, []);

    useEffect(() => {
        let metaGenresSearchText = ["Выбрать все", "Вся деловая литература", "Все любовные романы", "Вся фантастика", "Все приключение",
            "Все детективы и триллеры", "Вся литература для детей", "Вся документальная литература", "Всё про компьютеры и интернет",
            "Всё про науку и образование", "Вся драматургия", "Вся поэзия", "Вся проза", "Всё прочее"];

        let metaGenresArr = []
        let genresArr = []
        for (let i = 0; i < selectedOptions.length; i++) {
            if (metaGenresSearchText.indexOf(selectedOptions[i].label) > -1 && selectedOptions[i].label !== "Выбрать все") {
                metaGenresArr.push(selectedOptions[i].metaName)
            } else if (metaGenresSearchText.indexOf(selectedOptions[i].label) === -1 && selectedOptions[i].label !== "Выбрать все") {
                let allGenresWithSpecificMetaGenre = options.filter(val => val.metaName === selectedOptions[i].metaName);
                if (selectedOptions.indexOf(allGenresWithSpecificMetaGenre[0]) === -1) {
                    genresArr.push(selectedOptions[i].value)
                }
            }
        }
        setGenresMetaInclude(metaGenresArr.join('|'))
        setGenresInclude(genresArr.join('|'))
    }, [selectedOptions])

    let metaGenresSearchText = ["Выбрать все", "Вся деловая литература", "Все любовные романы", "Вся фантастика", "Все приключение",
        "Все детективы и триллеры", "Вся литература для детей", "Вся документальная литература", "Всё про компьютеры и интернет",
        "Всё про науку и образование", "Вся драматургия", "Вся поэзия", "Вся проза", "Всё прочее"];

    const updateGenres = () => {
        if (!areMetaGenresUpdated) {
            let allTags = document.getElementsByTagName("div");
            let found;

            for (let i = 0; i < allTags.length; i++) {
                if (metaGenresSearchText.indexOf(allTags[i].textContent) > -1 && (allTags[i].classList.contains("css-1qprcsu-option") || allTags[i].classList.contains("css-1vr111p-option"))) {
                    setAreMetaGenresUpdated(true)
                    found = allTags[i];
                    if (currentGenresSearchText !== '') {
                        found.style.cssText = `font-weight:normal !important; padding-left: 20px !important ;`
                        found.style.fontSize = 'inherit'
                    } else {
                        found.style.cssText = 'font-weight:bold !important; padding-left: 12px !important ;'
                        found.style.fontSize = '16px'
                    }
                }
            }
        }

    }

    const removeGenre = (e, isMeta) => {
        let currentGenres
        let pill = e.target
        if (isMeta) {
            let metaName = options[options.findIndex(val => val.label === pill.textContent)].metaName;

            let currentOptions = selectedOptions
            let allGenresWithSpecificMetaGenre = currentOptions.filter(val => val.metaName === metaName);
            allGenresWithSpecificMetaGenre.forEach(f => currentOptions.splice(currentOptions.findIndex(opt => opt.metaName === f.metaName), 1));
            setSelectedOptions([...currentOptions])

            currentGenres = genresMetaInclude.split('|')
            currentGenres.splice(currentGenres.indexOf(metaName), 1)
            setGenresMetaInclude(currentGenres.join('|'))
        } else {
            let genreDesc = options[options.findIndex(opt => opt.label === pill.textContent)].value

            let currentOptions = selectedOptions
            currentOptions.splice(currentOptions.indexOf(currentOptions.filter(val => val.value === genreDesc)[0]), 1);
            setSelectedOptions([...currentOptions])

            currentGenres = genresInclude.split('|')
            currentGenres.splice(currentGenres.indexOf(genreDesc), 1)
            setGenresInclude(currentGenres.join('|'))
        }
    }
    const showMoreBooks = () => {
        if (limit >= 750) {
            document.getElementById("show_more_btn").style.background = "#dc3545"
            document.getElementById("show_more_btn").innerHTML = 'Достигнуто максимальное кол-во книг на странице'
        } else {
            setLimit(limit + 60)
        }
    }

    const getGenresPill = () => {
        if ([...genresMetaInclude.split('|'), ...genresInclude.split('|')].length <= 10) {
            try {
                return [...genresMetaInclude.split('|').map(genre =>
                    genre !== '' ?
                        <span onClick={(e) => removeGenre(e, true)}
                              className="genre-pill">{options.filter(val => val.metaName === genre &&
                            metaGenresSearchText.indexOf(val.label) !== -1)[0].label}</span>
                        : null),
                    ...genresInclude.split('|').map(genre =>
                        genresInclude.split('') !== [] && genre !== '' ?
                            <span onClick={(e) => removeGenre(e, false)}
                                  className="genre-pill">{options.filter(val => val.value === genre)[0].label}</span>
                            : null)]
            } catch (e) {
                console.log(e)
            }
        }
    }

    function onChange(value, event) {
        if (event.action === 'input-change') {
            setCurrentGenresSearchText(value)
            setAreMetaGenresUpdated(false)
        }

        if (event.action === "select-option" && event.option.value === "*") {
            this.setState([{label: "Выбрать все", value: "*"}, ...this.options]);
        } else if (event.action === "deselect-option" &&
            event.option.value === "*") {
            this.setState([]);
        } else if (event.action === "select-option" && metaGenresSearchText.indexOf(event.option.label) !== -1) {
            let currentOptions = selectedOptions
            let allGenresWithSpecificMetaGenre = options.filter(val => val.metaName === event.option.metaName);
            allGenresWithSpecificMetaGenre.forEach(genre => currentOptions.indexOf(genre) === -1 ? currentOptions.push(genre) : null);
            if (currentOptions.length === options.length) {
                this.setState([{label: "Выбрать все", value: "*"}, ...currentOptions])
            }
            else {
                this.setState([...currentOptions])
            }
        } else if (event.action === "deselect-option" && metaGenresSearchText.includes(event.option.label)) {
            let currentOptions = value.filter(o => o.value !== "*")
            let allGenresWithSpecificMetaGenre = currentOptions.filter(val => val.metaName === event.option.metaName);
            allGenresWithSpecificMetaGenre.forEach(f => currentOptions.splice(currentOptions.findIndex(e => e.metaName === f.metaName), 1));
            this.setState([...currentOptions])
        } else if (event.action === "deselect-option") {
            let currentOptions = value.filter(o => o.value !== "*")
            let allGenresWithSpecificMetaGenre = currentOptions.filter(val => val.metaName === event.option.metaName);
            allGenresWithSpecificMetaGenre.forEach(f => metaGenresSearchText.indexOf(f.label) !== -1 ? currentOptions.splice(currentOptions.indexOf(f), 1) : null);
            this.setState([...currentOptions]);
        } else if (event.action === "select-option") {
            let currentOptions = selectedOptions
            currentOptions.push(event.option)
            let allSelectedGenresWithSpecificMetaGenre = currentOptions.filter(val => val.metaName === event.option.metaName);
            let allGenresWithSpecificMetaGenre = options.filter(val => val.metaName === event.option.metaName);
            let meta
            allGenresWithSpecificMetaGenre.forEach(f => metaGenresSearchText.indexOf(f.label) !== -1 ? meta = allGenresWithSpecificMetaGenre.splice(allGenresWithSpecificMetaGenre.indexOf(f), 1) : null);
            if (allSelectedGenresWithSpecificMetaGenre.length === allGenresWithSpecificMetaGenre.length) {
                currentOptions.push(meta[0])
            }
            if (currentOptions.length === options.length) {
                currentOptions = [{label: "Выбрать все", value: "*"}, ...options]
            }
            this.setState([...currentOptions])
        }
    }

    ReactMultiselectCheckboxes.defaultProps = {
        getDropdownButtonLabel({placeholderButtonLabel, value}) {
            if (!value) {
                return placeholderButtonLabel;
            }
            if (Array.isArray(value)) {
                let notGenreText = ["Выбрать все", "Вся деловая литература", "Все любовные романы", "Вся фантастика", "Все приключение",
                    "Все детективы и триллеры", "Вся литература для детей", "Вся документальная литература", "Всё про компьютеры и интернет",
                    "Всё про науку и образование", "Вся драматургия", "Вся поэзия", "Вся проза", "Всё прочее"];

                let genresCount = 0
                for (let i = 0; i < value.length; i++) {
                    if (!notGenreText.includes(value[i].label)) {
                        genresCount++
                    }
                }
                if (genresCount === 0) {
                    return placeholderButtonLabel;
                } else {
                    updateGenres()
                }
                if (genresCount === 1) {
                    return value[0].label;
                }
                return `Выбрано ${genresCount}`;
            }
            return value.label;
        }
    }

    return (
        <div>
            <div className="filters pb-2 border-bottom">
                <div className="container-fluid p-4">
                    <div className="row">
                        <div className="col-12 col-sm-4" id="yearFilter">
                            <div className="col-12 text-center">
                                <h5>Год</h5>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">От</span>
                                </div>
                                <input className={"form-control"} type="number" value={yearMin}
                                       onChange={e => setYearMin(e.target.value)} placeholder={"Минимальный год"}/>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">До</span>
                                </div>
                                <input className={"form-control"} type="number" value={yearMax}
                                       onChange={e => setYearMax(e.target.value)} placeholder={"Максимальный год"}/>
                            </div>
                        </div>

                        <div className="col-12 col-sm-4" id="ratingFilter">
                            <div className="col-12 text-center">
                                <h5>Средняя оценка</h5>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">От</span>
                                </div>
                                <input className={"form-control"} type="number" value={avgRateMin}
                                       onChange={e => setAvgRateMin(e.target.value)}
                                       placeholder={"Минимальная оценка"}/>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">До</span>
                                </div>
                                <input className={"form-control"} type="number" value={avgRateMax}
                                       onChange={e => setAvgRateMax(e.target.value)}
                                       placeholder={"Максимальная оценка"}/>
                            </div>
                        </div>

                        <div className="col-12 col-sm-4" id="gradesFilters">
                            <div className="col-12 text-center">
                                <h5>Всего голосов</h5>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">От</span>
                                </div>
                                <input className={"form-control"} type="number" value={totalGradesMin}
                                       onChange={e => setTotalGradesMin(e.target.value)}
                                       placeholder={"Минимальное кол-во голосов"}/>
                            </div>
                            <div className="input-group justify-content-center">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">До</span>
                                </div>
                                <input className={"form-control"} type="number" value={totalGradesMax}
                                       onChange={e => setTotalGradesMax(e.target.value)}
                                       placeholder={"Максимальное кол-во голосов"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="mb-3 genres-list">
                            {getGenresPill()}
                        </div>
                    </div>
                    <div>
                        <div onMouseMove={updateGenres} onClick={() => setAreMetaGenresUpdated(false)}
                             className="input-group d-flex justify-content-center">
                            <div className="input-group-prepend">
                                <label className="input-group-text" htmlFor="genresSelect">Жанры: </label>
                            </div>

                            <ReactMultiselectCheckboxes
                                width="200px"
                                placeholderButtonLabel={"Сортировка по жанрам"}
                                options={[{label: "Выбрать все", value: "*"}, ...options]}
                                defaultValue={[{label: "Выбрать все", value: "*"}, ...options]}
                                value={selectedOptions}
                                onChange={onChange}
                                setState={setSelectedOptions}
                                onInputChange={onChange}
                            />
                        </div>
                        <div className="input-group d-flex justify-content-center mt-2" id="sort_order">
                            <div className="input-group-prepend">
                                <label className="input-group-text" htmlFor="inputGroupSelect01">Сортировать по </label>
                            </div>
                            <select className="form-select" id="inputGroupSelect01" value={orderBy}
                                    onChange={e => setOrderBy(e.target.value)}>
                                <option value="time_desc">Времени &#8595;</option>
                                <option value="time_asc">Времени &#8593;</option>
                                <option value="total_desc">Количеству оценок &#8595;</option>
                                <option value="total_asc">Количеству оценок &#8593;</option>
                                <option value="average_desc">Средней оценке &#8595;</option>
                                <option value="average_asc">Средней оценке &#8593;</option>
                            </select>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div className="settings">
                        {
                            !props.author_id ?
                                <React.Fragment>
                                    <div className="form-check form-switch d-flex justify-content-center mt-3">
                                        <input className="form-check-input" type="checkbox" value=""
                                               onClick={() => setShowOnlySubscribedAuthorsBooks(!showOnlySubscribedAuthorsBooks)}
                                               id="showOnlySubscribedAuthorsBooks" disabled={!props.currentUser}
                                               checked={showOnlySubscribedAuthorsBooks}/>
                                        <label className="form-check-label ms-2"
                                               htmlFor="showOnlySubscribedAuthorsBooks">
                                            Показывать только книги тех авторов на которых вы подписаны
                                        </label>
                                    </div>
                                    {!props.currentUser ?
                                        <p className="small text-center">&nbsp;*Данная функция доступна только
                                            зарегестрированным пользователям</p> : null}
                                </React.Fragment>
                                : null
                        }
                        <React.Fragment>
                            <div className="form-check form-switch d-flex justify-content-center mt-3 w-100">
                                <input className="form-check-input" type="checkbox" value=""
                                       onClick={() => setShowOnlyBooksWithoutUserGrades(!showOnlyBooksWithoutUserGrades)}
                                       id="showOnlyBooksWithoutUserGrades" disabled={!props.currentUser}
                                       checked={showOnlyBooksWithoutUserGrades}/>
                                <label className="form-check-label ms-2" htmlFor="showOnlyBooksWithoutUserGrades">
                                    Скрыть оценённые книги
                                    <span className="badge rounded-pill text-bg-primary ms-2">Новое</span>
                                </label>
                            </div>
                            {!props.currentUser ? <p className="small text-center">&nbsp;*Данная функция доступна только
                                зарегестрированным пользователям</p> : null}
                        </React.Fragment>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <button className="border-0 btn btn-success submitButton"
                                onClick={fetchBooks}>Подтвердить
                        </button>
                        {
                            props.author_id ? null : <button onClick={resetFilters}
                                                             className={"btn border-0 ms-4 btn-outline-secondary resetFilters"}>Сбросить
                                фильтры </button>
                        }
                    </div>


                </div>
            </div>

            <div className="App">
                {!loading
                    ? books.length !== 0
                        ? (
                            books.map((book) => (
                                <BookCard {...book} currentUser={props.currentUser} screenWidth={screenWidth}/>)))
                        : <p className="books-not-found-text text-center">По вашему запросу не было найдено книг :(</p>

                    :
                    <div className="align-items-center justify-content-center d-flex mt-5">
                        <BouncingBalls color={'orange'} width={'58px'} height={'20px'}/>
                    </div>
                }
            </div>
            <div className="d-flex justify-content-center show_more">
                <button onClick={showMoreBooks} className={"btn btn-info btn-sm"} id="show_more_btn">Показать больше
                </button>
            </div>
        </div>
    );
}

export default Books;

import api from "../http";

const getFollow = () => {
    return api
        .get("/follow")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error
        });

};

const addFollow = (author_id) => {
    return api
        .post("/follow", {
            "author_id": author_id
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error
        });

};

const removeFollow = (author_id) => {
    return api
        .delete("/follow", {
            params: {
                "author_id": author_id
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error
        });

};

let followService = {
    getFollow,
    addFollow,
    removeFollow
}

export default followService
import React from "react";
import "./styles.css"

function AboutUs() {
    return (
        <div className="container about-container">
            <div className="text-center">
                <h6 className="about-text mx-auto mt-2">Этот сервис помогает не запутаться в книжных новинках и быстро
                    выбрать самые высокооцененные и популярные книги.</h6>
            </div>
            <div className="text-center">
                <h6 className="about-text mx-auto">Начните знакомство с
                    <a href="https://podborknig.ru/?limit=60&year_min=2023&year_max=2023&avgRateMin=3.5&avgRateMax=5&total_grades_min=15&total_grades_max=999999&orderBy=total_desc&genreMetaInclude=%D0%94%D0%B5%D1%82%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D1%8B+%D0%B8+%D0%A2%D1%80%D0%B8%D0%BB%D0%BB%D0%B5%D1%80%D1%8B,%D0%9B%D1%8E%D0%B1%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5+%D1%80%D0%BE%D0%BC%D0%B0%D0%BD%D1%8B,%D0%9F%D1%80%D0%B8%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D1%8F,%D0%9F%D1%80%D0%BE%D0%B7%D0%B0,%D0%A4%D0%B0%D0%BD%D1%82%D0%B0%D1%81%D1%82%D0%B8%D0%BA%D0%B0"> топом
                        книг за 2023</a>,
                    <a href="https://podborknig.ru/?limit=60&year_min=0&year_max=2022&avgRateMin=3.5&avgRateMax=5&total_grades_min=60&total_grades_max=999999&orderBy=average_desc&genreMetaInclude=%D0%94%D0%B5%D1%82%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D1%8B+%D0%B8+%D0%A2%D1%80%D0%B8%D0%BB%D0%BB%D0%B5%D1%80%D1%8B,%D0%9B%D1%8E%D0%B1%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5+%D1%80%D0%BE%D0%BC%D0%B0%D0%BD%D1%8B,%D0%9F%D1%80%D0%B8%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D1%8F,%D0%9F%D1%80%D0%BE%D0%B7%D0%B0,%D0%A4%D0%B0%D0%BD%D1%82%D0%B0%D1%81%D1%82%D0%B8%D0%BA%D0%B0"> книгами
                        с высокими оценками</a> и
                    <a href="https://podborknig.ru/?limit=60&year_min=1&year_max=2022&avgRateMin=3.5&avgRateMax=5&total_grades_min=400&total_grades_max=999999&orderBy=average_desc&genreMetaInclude=%D0%94%D0%B5%D1%82%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D1%8B+%D0%B8+%D0%A2%D1%80%D0%B8%D0%BB%D0%BB%D0%B5%D1%80%D1%8B,%D0%9B%D1%8E%D0%B1%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5+%D1%80%D0%BE%D0%BC%D0%B0%D0%BD%D1%8B,%D0%9F%D1%80%D0%B8%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D1%8F,%D0%9F%D1%80%D0%BE%D0%B7%D0%B0,%D0%A4%D0%B0%D0%BD%D1%82%D0%B0%D1%81%D1%82%D0%B8%D0%BA%D0%B0"> самыми
                        популярными книгами</a></h6>
            </div>
        </div>
    );
}

export default AboutUs;

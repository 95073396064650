import React, {useEffect, useState} from 'react';
import AuthService from "../../services/auth.service";
import {useParams} from "react-router-dom";
import getAuthor from "../../services/author.service";
import "./style.css";
import Books from "../Books";
import Navbar from "../Navbar";
import followService from "../../services/follow.service";

export default function AuthorPage() {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [author, setAuthor] = useState({})
    const [userFollowing, setUserFollowing] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const author_id = useParams().author_id;

    useEffect(async () => {
        let user
        if (localStorage.getItem('token')) {
            user = await AuthService.checkAuth()
            if (user) {
                setCurrentUser(user);
            }
        }

        getAuthor(author_id, user ? user["user_id"] : 0)
            .then(
                (data) => {
                    if (data.author) {
                        setAuthor(data.author)
                    }
                    if (data.author.user_following === 'true') {
                        setUserFollowing(true)
                    } else if (data.author.user_following === 'false') {
                        setUserFollowing(false)
                    }
                },
                (error) => {
                    console.log(error.response.data)
                })
    }, []);

    let followButtonHandler = async (e) => {
        try {
            if (userFollowing) {
                followService.removeFollow(author_id)
                    .then(
                        (response) => {
                            setUserFollowing(false)
                        },
                        (error) => {
                            setShowAlert(true)
                            console.log(error.response.data.message)

                        })
            } else {
                followService.addFollow(author_id)
                    .then(
                        (response) => {
                            setUserFollowing(true)
                        },
                        (error) => {
                            setShowAlert(true)
                            console.log(error.response.data.message)
                        })
            }
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <div>
            {showAlert ? <div className="alert alert-warning alert-dismissible mb-0 show fade" role="alert">
                Функция подписки на автора работает только для зарегистрированных пользователей. Пожалуйста <a
                href="/signup">зарегистрируйтесь</a> или <a href="/login">войдите </a> чтобы подписаться на автора!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"
                        onClick={() => setShowAlert(false)}/>
            </div> : null}
            <Navbar currentUser={currentUser} setCurrentUser={setCurrentUser} />
            <div className="container-fluid">
                <h1 className="text-center author-name">{Object.keys(author).length === 0 ? "Автор не найден" : `${author.firstname} ${author.middlename} ${author.lastname}`}</h1>
                <div className="text-center">
                    <p className="follow-information">
                        {userFollowing ? "Вы подписаны" : "Вы не подписаны"}
                    </p>
                    <div className="w-100 text-center">
                        <button style={{"background-color": userFollowing ? "#4E9F3D" : "#7F8487"}}
                                onClick={followButtonHandler} className="btn text-white follow-button">
                            {userFollowing ? "Отписаться" : "Подписаться"}
                        </button>
                    </div>
                </div>
                <Books currentUser={currentUser} author_id={author_id}/>
            </div>
        </div>
    );
}


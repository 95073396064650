import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/js/brands"
import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/regular"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./index.css";
import Blog from "./components/Blog/index"
import Login from "./components/Login";
import Signup from "./components/Signup";
import Cabinet from "./components/Cabinet";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "jquery/dist/jquery.min";
import "@popperjs/core";
import "bootstrap/dist/js/bootstrap.min";
import AuthorPage from "./components/AuthorPage";
import SubscriptionsPage from "./components/SubscriptionsPage";
import Policy from "./components/Policy/Policy";
import Terms from "./components/Terms/Terms";

const root = ReactDOM.createRoot(document.getElementById("root"))


root.render(
    <Router>
        <Routes>
            <Route exact path="/" element={<App/>}/>
            <Route path="/blog" element={<Blog/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/cabinet" element={<Cabinet/>}/>
            <Route path="/subscriptions" element={<SubscriptionsPage/>}/>
            <Route path="/author/:author_id" element={<AuthorPage/>}/>
            <Route path="/policy" element={<Policy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route element={<App/>}/>
        </Routes>
    </Router>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

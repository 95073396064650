import "./style.css";
import Rating from "../Rating";
import {useEffect, useState} from "react";
import $ from "jquery"
import gradeService from "../../services/grade.service";
import {alert} from "react-bootstrap-confirmation";

export default function BookCard({
                                     author_id: authorId,
                                     authors,
                                     title,
                                     bookid: bookId,
                                     avg_rate,
                                     total_grades,
                                     genres,
                                     user_grade,
                                     currentUser,
                                     screenWidth
                                 }) {
    const [userGrade, setUserGrade] = useState(user_grade)
    const [isMouseOnUserGrade, setIsMouseOnUserGrade] = useState(false)
    const [userGradeAnimation, setUserGradeAnimation] = useState(null)
    const [authorsList, setAuthorsList] = useState([])
    const [gradeCircleId] = useState(parseInt(Date.now() * Math.random()).toString())
    useEffect(() => {
        let authorsSplit
        if (authors) {
            authorsSplit = authors.split("|");
        } else {
            return <p>Неизвестный автор</p>
        }
        let authorsIdList = authorId.split('|')
        let finalList = []

        for (let i = 0; i <= authorsIdList.length - 1; i++) {
            finalList.push(<a
                href={`/author/${authorsIdList[i]}`}>{i !== 0 ? ', ' : ''}{authorsSplit[i].replace(';', ' ')}</a>)
        }

        setAuthorsList(finalList)
    }, [])

    let ourRating = (avg_rate * 2).toFixed(2);
    let genresSplit
    try {
        if (genres.split("|")) {
            genresSplit = genres.split("|");
        }
    } catch (e) {
        console.log("bookId is: ", bookId)
        console.log(e)
        return null
    }

    let arrayOfGenres = genresSplit.map(function (str) {
        return <li>{str}</li>;
    });

    const handleGradeMouseClick = () => {
        let grade = userGrade
        setUserGrade(null)
        gradeService.removeGrade(bookId)
            .then((res) => {
                if (res.status !== 200) {
                    alert("Произошла ошибка", {okButtonStyle: "danger"})
                    setUserGrade(grade)
                }
            })
    }

    const handleGradeMouseEnter = (e) => {
        let elementId = `#${e.currentTarget.childNodes[0].id.toString()}`
        clearTimeout(userGradeAnimation)
        setUserGradeAnimation(setTimeout(() => {
            $.when($(elementId).css({opacity: 1}).animate({opacity: 0}, 300))
                .then(() => {
                    setIsMouseOnUserGrade(true)
                    $(elementId).css({opacity: 0}).animate({opacity: 1}, 300);
                })
        }, 100))
    }

    const handleGradeMouseLeave = (e) => {
        let elementId = `#${e.currentTarget.childNodes[0].id.toString()}`

        clearTimeout(userGradeAnimation);
        setUserGradeAnimation(setTimeout(() => {
            $.when($(elementId).css({opacity: 1}).animate({opacity: 0}, 300))
                .then(() => {
                    setIsMouseOnUserGrade(false)
                    $(elementId).css({opacity: 0}).animate({opacity: 1}, 300);
                })
        }, 100))

    }

    return (<div className="bookcard__container">
        <p className="bookcard__title">{title}</p>
        <p className="bookcard__author">{authorsList.length > 2 ? [...authorsList.splice(0, 2),
            <p>и другие..</p>] : authorsList}</p>

        <p className="bookcard__genres">
            {genresSplit[0]} <span>{arrayOfGenres}</span>
        </p>
        <div className="d-flex align-items-center mw-100">
            <div
                className="d-flex justify-content-start align-items-center w-100 h-100 rating-and-grade-block position-relative">
                <div className="d-flex w-100 align-items-center rating-block">
                    <Rating screenWidth={screenWidth} ratingValue={avg_rate} currentUser={currentUser}
                            bookId={bookId} userGrade={userGrade} setUserGrade={setUserGrade}/>
                    <div className="rating">
                        <p
                            className="rating-number"
                            style={{
                                'margin-left': screenWidth > 576 ? "45px" : "15px",
                                color: ourRating < 5 ? "#FF0000" : ourRating < 7 ? "#ffc800" : "#549f76",
                            }}
                        >
                            {ourRating}
                        </p>
                        <p className="quantity-of-rating" style={{
                            'color': "rgba(0, 0, 0, 0.5)",
                            'fontFamily': "'Comfortaa', cursive, serif",
                            'fontSize': "17.5px"
                        }}>({total_grades})</p>
                    </div>
                </div>

                {screenWidth > 576 && userGrade
                        ?
                        <div onClick={(e) => handleGradeMouseClick(e)}
                             className="position-absolute top-0 start-0 grade-block d-flex justify-content-end w-100 h-100 align-items-end">
                            <div onMouseEnter={(e) => handleGradeMouseEnter(e)}
                                 onMouseLeave={(e) => handleGradeMouseLeave(e)}>
                                {!isMouseOnUserGrade
                                    ?
                                    <div id={gradeCircleId} className="grade-circle">
                                        <span className="grade-text">{userGrade}</span>
                                    </div>
                                    :
                                    <div id={gradeCircleId} className="cancel-grade-circle grade-circle">
                                        <div id="cancel-grade"/>
                                    </div>
                                }
                            </div>
                        </div>
                        : userGrade ?
                        <div
                            className="position-absolute top-0 start-0 grade-block d-flex justify-content-end h-100 w-100 align-items-end">
                            <div id={gradeCircleId} className="grade-circle position-relative">
                                <span className="grade-text">{userGrade}</span>
                                <i onClick={handleGradeMouseClick}
                                   className="mobile-cancel-grade-button position-absolute fa-solid fa-circle-xmark"/>
                            </div>
                        </div>
                    : null

                }
            </div>
        </div>
    </div>);
}

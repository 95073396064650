import React from "react";
import "./style.css";

function AboutUs() {
    return (
        <>
            <div className="panel-body" id="document-content">
                <h2>Пользовательское Соглашение</h2>
                <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем
                    podborknig.ru (далее Podborknig.ru или Администрация) с одной стороны и пользователем сайта с
                    другой.</p>
                <p>Сайт Podborknig.ru не является средством массовой информации. Используя сайт, Вы соглашаетесь с
                    условиями данного соглашения. <b>Если Вы не согласны с условиями данного соглашения, не используйте
                        сайт Podborknig.ru!</b></p>
                <h3>Предмет соглашения</h3>
                <p><b>Администрация предоставляет пользователю право на размещение на сайте следующей информации:</b>
                </p>
                <ul>
                    <li>Текстовой информации</li>
                </ul>
                <h3>Права и обязанности сторон</h3>
                <p><b>Пользователь имеет право:</b></p>
                <ul>
                    <li>осуществлять поиск информации на сайте</li>
                    <li>получать информацию на сайте</li>
                    <li>копировать информацию на другие сайты с указанием источника</li>
                    <li>требовать от администрации скрытия любой информации о пользователе</li>
                    <li>использовать информацию сайта в личных некоммерческих целях</li>
                    <li>использовать информацию сайта в коммерческих целях с разрешения Администрации</li>
                </ul>
                <p><b>Администрация имеет право:</b></p>
                <ul>
                    <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
                    <li>ограничивать доступ к любой информации на сайте</li>
                    <li>создавать, изменять, удалять информацию</li>
                    <li>удалять учетные записи</li>
                    <li>отказывать в регистрации без объяснения причин</li>
                </ul>
                <h3>Пользователь обязуется:</h3>
                <ul>
                    <li>не распространять информацию, которая направлена на пропаганду войны, разжигание национальной,
                        расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой
                        предусмотрена уголовная или административная ответственность
                    </li>
                    <li>не нарушать работоспособность сайта</li>
                    <li>не совершать действия, направленные на введение других Пользователей в заблуждение</li>
                    <li>не регистрировать учетную запись от имени или вместо другого лица за исключением случаев,
                        предусмотренных законодательством РФ
                    </li>
                    <li>не размещать материалы рекламного, эротического, порнографического или оскорбительного
                        характера, а также иную информацию, размещение которой запрещено или противоречит нормам
                        действующего законодательства РФ
                    </li>
                    <li>не использовать скрипты (программы) для автоматизированного сбора информации и/или
                        взаимодействия с Сайтом и его Сервисами
                    </li>
                </ul>
                <h3>Ответственность сторон</h3>
                <p>- пользователь лично несет полную ответственность за распространяемую им информацию</p>
                <p>- администрация не несет никакой ответственности за достоверность информации, скопированной из других
                    источников</p>
                <p>- администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных
                    услуг</p>
                <p>- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</p>
                <p>- администрация не гарантирует сохранность информации, размещённой Пользователем, а
                    также бесперебойную работу информационного ресурса</p>
                <h3>Условия действия Соглашения</h3>
                <p>Данное Соглашение вступает в силу при регистрации на сайте. Соглашение перестает действовать при
                    появлении его новой версии. Администрация оставляет за собой право в одностороннем порядке изменять
                    данное соглашение по своему усмотрению. При изменении соглашения, в некоторых случаях, администрация
                    может оповестить пользователей удобным для нее способом.</p>
            </div>
        </>
    );
}

export default AboutUs;

import React, {useEffect, useState} from 'react';
import "./index.css";
import AuthService from "../../services/auth.service";
import Navbar from "../Navbar";
import api from "../../http";

export default function Blog() {
    const [blog, setBlog] = useState([]);
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        if (blog.length === 0) {
            getBlog()
        }
    }, [blog]);

    useEffect(async () => {
        let user
        if (localStorage.getItem('token')) {
            user = await AuthService.checkAuth()
            if (user) {
                setCurrentUser(user);
            }
        }
    }, []);

    function getBlog() {
        api.get('/blog')
            .then(response => {
                setBlog(response.data)
            })
    }

    function getBlogCard(data) {
        function toDate(milliseconds) {
            var date = new Date(milliseconds);
            var y = date.getFullYear()
            var m = date.getMonth() + 1;
            var d = date.getDate();
            m = (m < 10) ? '0' + m : m;
            d = (d < 10) ? '0' + d : d;
            return [d, m, y].join('-');
        }

        return (
            <>
                <div className="blog-card card">
                    <h3 className="card-header">{data.header}</h3>
                    <div className="card-body">
                        <p>{data.text}</p>
                    </div>
                    <div className="card-footer d-flex flex-row-reverse">
                        <p className="date me-2">{toDate(Date.parse(data['created_on']))}</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <div>
                <Navbar currentUser={currentUser} setCurrentUser={setCurrentUser}/>
            </div>
            <div className="blog-container">
                {
                    blog.map((data) => (
                        getBlogCard(data)
                    ))
                }
            </div>
        </div>
    );
}

